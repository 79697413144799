html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: -webkit-fill-available;
}

* {
  box-sizing: inherit;
}

:before {
  box-sizing: inherit;
}

:after {
  box-sizing: inherit;
}

body {
  color: #333;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

body.dark-mode {
  color: #f5f5f5;
  background-color: #333;
}

main {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100vh;
  min-height: -webkit-fill-available;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  overflow-x: hidden;
}

.container {
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 80vw;
  margin-top: 20px;
  padding: 20px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
}

.container.dark-mode {
  color: #f5f5f5;
  background-color: #202020;
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, .1);
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

@media (min-width: 768px) {
  .container {
    width: 480px;
  }
}

.footer {
  margin-top: auto;
  padding: 20px 10px;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
}

p {
  font-size: 1.25rem;
}

form {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

label {
  margin-top: 10px;
  margin-bottom: 10px;
}

input, select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
}

input.dark-mode, select.dark-mode {
  color: #f5f5f5;
  background-color: #333;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
}

button.dark-mode {
  color: #333;
  background-color: #f5f5f5;
}

button:hover {
  background-color: #0062cc;
}

button:hover.dark-mode {
  color: #f5f5f5;
  background-color: #0d0d0d;
}

#result {
  margin-top: 20px;
}

.shipping-cost {
  font-size: 1.25rem;
  font-weight: bold;
}

a {
  color: #007bff;
  text-decoration: none;
}

a.dark-mode {
  color: #ffb300;
}

a:visited {
  color: #007bff;
}

a:visited.dark-mode {
  color: #ffb300;
}

a:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 2px #66b0ff;
  box-shadow: 0 0 0 2px #66b0ff;
}

a:focus.dark-mode {
  -webkit-box-shadow: 0 0 0 2px #004a99;
  box-shadow: 0 0 0 2px #004a99;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: #0062cc;
}

a:active.dark-mode {
  color: #3395ff;
}

a[href^="http"] {
  background: url("external-link-light.14376af0.svg") 100% 0 no-repeat;
  padding-right: 2rem;
}

a[href^="http"].dark-mode {
  background: url("external-link-dark.b930fd28.svg") 100% 0 no-repeat;
}

/*# sourceMappingURL=index.b69072d8.css.map */
