// Breakpoints

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Colours

$light-background: #f5f5f5;
$light-text-color: #333;
$dark-background: #333;
$dark-text-color: #f5f5f5;
$primary-color: #007bff;
$secondary-color: #ffb300;
