@use "sass:color";
@import "base";
@import "variables";
@import "mixins";

body {
  margin: 0;
  padding: 0;
  background-color: $light-background;
  color: $light-text-color;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  &.dark-mode {
    background-color: $dark-background;
    color: $dark-text-color;
  }
}

main {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: -webkit-fill-available; /* stylelint-disable-line value-no-vendor-prefix */
}

.container {
  margin-top: 20px;
  background-color: $light-background;
  border-radius: 5px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  padding: 20px;
  width: 80vw;

  &.dark-mode {
    background-color: color.adjust($dark-background, $lightness: -7.5%);
    color: $dark-text-color;
    box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  }

  @include md {
    width: 480px;
  }
}

.footer {
  padding: 20px 10px;
  margin-top: auto;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
}

p {
  font-size: 1.25rem;
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  margin-bottom: 10px;
  margin-top: 10px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;

  &.dark-mode {
    background-color: $dark-background;
    color: $dark-text-color;
  }
}

button {
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1rem;

  &.dark-mode {
    background-color: $light-background;
    color: $light-text-color;
  }

  &:hover {
    background-color: color.adjust($primary-color, $lightness: -10%);

    &.dark-mode {
      background-color: color.adjust($dark-background, $lightness: -15%);
      color: $dark-text-color;
    }
  }
}

#result {
  margin-top: 20px;
}

.shipping-cost {
  font-size: 1.25rem;
  font-weight: bold;
}

a {
  color: $primary-color;
  text-decoration: none;

  &.dark-mode {
    color: $secondary-color;
  }

  &:visited {
    color: $primary-color;

    &.dark-mode {
      color: $secondary-color;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px color.adjust($primary-color, $lightness: 20%);

    &.dark-mode {
      box-shadow: 0 0 0 2px color.adjust($primary-color, $lightness: -20%);
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: color.adjust($primary-color, $lightness: -10%);

    &.dark-mode {
      color: color.adjust($primary-color, $lightness: 10%);
    }
  }
}

a[href^="http"] {
  padding-right: 2rem;
  background: url("../img/external-link-light.svg") no-repeat 100% 0;

  &.dark-mode {
    background: url("../img/external-link-dark.svg") no-repeat 100% 0;
  }
}
